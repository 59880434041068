import React, { lazy, useEffect } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { themeChange } from 'theme-change'
import initializeApp from './app/init';
import { useAuth } from './contexts/AuthContext'

// Importing pages
const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const Register = lazy(() => import('./pages/Register'))
const Documentation = lazy(() => import('./pages/Documentation'))


// Initializing different libraries
initializeApp()


function App() {

  const { currentUser, isAdmin } = useAuth()
  
  //Check for authentication
  const PUBLIC_ROUTES = ["login", "forgot-password", "register", "documentation", "forbidden"]
  const USER_ROUTES = [...PUBLIC_ROUTES] //Add user routes here
  const isPublicPage = PUBLIC_ROUTES.some( r => window.location.href.includes(r))
  const isUserPage = USER_ROUTES.some( r => window.location.href.includes(r))
  if(!currentUser && !isPublicPage){
    window.location.href = '/login'
  } else if(!isUserPage && !isAdmin){
    window.location.href = '/app/forbidden'
  }

  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false)
  }, [])


  return (
    <>
      <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/register" element={<Register />} />
            <Route path="/documentation" element={<Documentation />} />

            {/* Place new routes over this */}
            <Route path="/app/*" element={<Layout />} />

            <Route path="*" element={<Navigate to={currentUser ? "/app/welcome" : "/login"} replace />} />

          </Routes>
      </Router>
    </>
  )
}

export default App
