import React, { useContext, useState, useEffect } from "react"
import { auth, db } from "../firebase"
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updateEmail,
  updatePassword,
  onAuthStateChanged,
  updateProfile,
} from 'firebase/auth';

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [isAdmin, setIsAdmin] = useState(false)
  const [loading, setLoading] = useState(true)

  function signup(name, email, password) {
    return createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
      return updateProfile(auth.currentUser, {
        displayName: name
      }).then(() => { return userCredential })
    })
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  function logout() {
    return signOut(auth)
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email)
  }

  function updateUserEmail(email) {
    return updateEmail(auth.currentUser, email)
  }

  function updateUserName(name) {
    return updateProfile(auth.currentUser, { displayName: name })
  }

  function updatePhotoURL(photoURL) {
    return updateProfile(auth.currentUser, { photoURL })
  }

  function updateUserPassword(password) {
    return updatePassword(auth.currentUser, password)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
      if (user !== null) {
        const docRef = doc(db, "userPermissions", auth.currentUser.uid);
        const docSnap = getDoc(docRef).then((docSnap) => {
          if (docSnap.exists()) {
            setIsAdmin(docSnap.data().isAdmin)
          }
        }).then(() => {
          setLoading(false)
        }).catch((error) => {
          console.log("Error getting document:", error);
          setLoading(false)
        })
      }
      else {
        setLoading(false)
      }
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    isAdmin,
    login,
    signup,
    logout,
    resetPassword,
    updateUserEmail,
    updatePassword,
    updateUserName,
    updatePhotoURL,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}